@import '~/styles/common/layout';

.content {
  background-color: rgb(255 255 255 / 90%);
}

.labels {
  @include row;
  flex-flow: wrap;

  border-top: 1px solid #e4e4e4;
  padding-top: 10px;
  margin: 20px 20px 10px;

  .label {
    @include ellipsis-limit;

    border-radius: 5px;
    padding: 5px 10px;

    color: #929292;
    background: #eaeaea;

    margin-bottom: 5px;

    &:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.accessPoints {
  @include column;

  margin: 0 20px 20px;
  padding: 15px;

  border: 1px solid #d4d4d4;
  border-radius: 10px;

  .l7groups {
    margin-top: 7px;
  }

  .endpointsLimited {
    padding-left: 10px;
    margin-top: 5px;

    color: #929a9f;
  }
}

.accessPoints + .labels {
  margin-top: 0;
}
